<template>
  <div id="coming-soon-page" class="coming-soon-page">
    <div class="coming-soon-page__wrapper">
<!--      <iframe class="coming-soon-page__iframe-page" src="https://market.verb.tech/sellers/"></iframe>-->
<!--      <div class="coming-soon-page__header">
        <img :src="require('src/assets/logo/logo-shoprz.png')" class="coming-soon-page__logo" alt="">
&lt;!&ndash;        <div class="coming-soon-page__button" @click="onSignUpClick()">
          Start your journey
        </div>&ndash;&gt;
      </div>-->

      <div class="coming-soon-page__top">
<!--        <div class="coming-soon-page__title">
          Your products. <br> Viewed By Millions.
        </div>

        <p class="coming-soon-page__description">
          Shoprz provides an online meeting place for friends and family to meet, chat,  shop, and have fun together from anywhere in the world.
        </p>-->

<!--        <div class="coming-soon-page__video-wrapper">
          <video class="coming-soon-page__video" width="100%" height="100%" controls controlsList="nodownload" playsinline :poster="require('src/assets/images/video-poster.png')">
            <source src="https://cdn-files.vidnt.net/prod/mp4/2201_Verb_Market%20Platform%20Differentiators_22-02-02_v02_BS.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>-->
      </div>

<!--      <img :src="require('src/assets/images/coming-soon.png')" class="coming-soon-page__image" alt="">-->
<!--      <img :src="require('src/assets/images/coming-soon-mobile.png')" class="coming-soon-page__image coming-soon-page__image&#45;&#45;mobile" alt="">-->

<!--      <div id="coming-soon-page-iframe" class="coming-soon-page__iframe-wrapper">
        <iframe class="coming-soon-page__iframe" src="https://fs16.formsite.com/res/showFormEmbed?EParam=B6fiTn-RcO6JWJWon2cQ8GrYe2Y6sJfY&1959971909&EmbedId=1959971909" frameborder="0"/>
      </div>-->

      <div class="coming-soon-page__hidden-block" @click="showPasswordPopup()"/>

      <div v-if="isShowPasswordPopup" class="coming-soon-page__popup password-popup" @click="onCloseModal($event)">
        <div class="password-popup__container">
          <input v-model="passwordInput" type="password" class="password-popup__input" placeholder="Enter password">

          <div v-if="isShowPasswordPopupError" class="password-popup__error">
            Wrong password
          </div>

          <button class="password-popup__submit" @click="onSubmitPasswordPopup()">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { amsClient } from 'src/service/ams';

  export default {
    name: 'ComingSoonPage',
    data() {
      return {
        password: 'U2gwcGVSWjIz',
        isShowPasswordPopup: false,
        isShowPasswordPopupError: false,
        passwordInput: '',
        content: null,
        body: null,
      };
    },
    mounted() {
      this.content = document.getElementById('app-content');
      this.content.classList.add('z-index');

      this.body = document.querySelector('body');
      this.body.classList.add('is-coming-soon');
    },
    beforeDestroy() {
      this.content.classList.remove('z-index');
      this.content = null;

      this.body.classList.remove('is-coming-soon');
      this.body = null;
    },
    methods: {
      onSignUpClick() {
        const wrapper = document.getElementById('coming-soon-page');
        const block = document.getElementById('coming-soon-page-iframe');

        wrapper.scroll({ top: block.offsetTop, behavior: 'smooth' });
      },
      showPasswordPopup() {
        this.isShowPasswordPopup = true;
      },
      onSubmitPasswordPopup() {
        if (this.password === btoa(this.passwordInput)) {
          this.setDataToLocalStorage(this.passwordInput);

          this.closePasswordPopup();

          const storeSettings = amsClient.get('conf.store');

          if (storeSettings?.is_protected) {
            this.$router.push('/protected');
          } else {
            this.$router.push('/');
          }
          
        } else {
          this.isShowPasswordPopupError = true;
        }
      },
      onCloseModal(event) {
        if (event.target === event.currentTarget) {
          this.closePasswordPopup();
        }
      },
      closePasswordPopup() {
        this.isShowPasswordPopupError = false;
        this.isShowPasswordPopup = false;
        this.passwordInput = '';
      },
      setDataToLocalStorage(value) {
        localStorage.setItem('iepp', btoa(value));
      },
    },
  };
</script>

<style scoped lang="scss">
  *::-webkit-scrollbar {
    display: none;
  }

  .coming-soon-page {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    background-color: #f7f7f7;
    &__header {
      display: flex;
      align-items: center;
      padding: 35px 20px;
    }
    &__logo {
      margin-right: auto;
    }
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 15px;
      min-width: 100px;
      border: 1px solid #E5E7EE;
      box-sizing: border-box;
      border-radius: 10px;
      font-weight: 500;
      font-size: 15px;
      line-height: 26px;
      text-align: center;
      &:hover {
        cursor: pointer;
      }
    }
    &__top {
      margin-bottom: 140px;
      text-align: center;
      color: #343434;
    }
    &__title {
      margin-bottom: 35px;
      font-weight: 600;
      font-size: 45px;
      line-height: 60px;
    }
    &__description {
      font-size: 22px;
      line-height: 32px;
      max-width: 735px;
      width: 100%;
      margin: 0 auto 40px;
    }
    &__video-wrapper {
      max-width: 925px;
      width: 100%;
      height: 520px;
      margin: 0 auto;
    }
    &__video {
      border-radius: 20px;
    }
    &__image {
      margin-bottom: 110px;
      width: 100%;
      &--mobile {
        display: none;
      }
    }
    &__iframe-wrapper {
      margin: 0 auto;
      max-width: 600px;
      width: 100%;
      height: 850px;
    }
    &__iframe {
      width: 100%;
      height: 100%;
    }
    &__hidden-block {
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      position: fixed;
      top: 2px;
      right: 19px;
    }

    &__iframe-page {
      border: none;
      width: 100%;
      height: 100vh;
    }

    @media(max-width: 767px) {
      &__wrapper {
        padding-bottom: 100px;
      }
      &__header {
        margin-bottom: 20px;
        padding: 20px;
      }
      &__header-text {
        display: none;
      }
      &__top {
        padding: 15px;
        margin-bottom: 50px;
      }
      &__title {
        margin-bottom: 30px;
        font-size: 35px;
        line-height: 35px;
      }
      &__description {
        margin-bottom: 30px;
        font-size: 20px;
      }
      &__video-wrapper {
        max-width: 335px;
        height: 190px;
      }
      &__video {
        border-radius: 10px;
      }
      &__image {
        display: none;
        &--mobile {
          display: block;
          margin-bottom: 15px;
        }
      }
      &__iframe-wrapper {
        height: 1200px;
      }
    }
  }
  .password-popup {
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .8);

    &__container {
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 20px;
      max-width: 300px;
      width: 100%;
      transform: translate(-50%, -50%);
      background: white;
      border-radius: 4px;
    }

    &__input {
      width: 100%;
      padding: 10px;
      margin-bottom: 20px;
      font-size: 18px;
      border: 1px solid black;
      border-radius: 4px;
    }

    &__error {
      margin-bottom: 20px;
      font-size: 14px;
      color: red;
    }

    &__submit {
      width: 100%;
      padding: 10px;
      font-size: 18px;
      border-radius: 4px;
      background: #798B96;
      color: white;
    }
  }
</style>
